export enum FeatureName {
    BUSINESS_LOG = "BUSINESS_LOG",
    CURRICULUM = "CURRICULUM",
    HOME_PROJECTS = "HOME_PROJECTS",
    NON_CONTIGUOUS_ENROLLMENTS = "NON_CONTIGUOUS_ENROLLMENTS",
    PROJECT_RESOURCES = "PROJECT_RESOURCES",
    PUNCH_CARDS = "PUNCH_CARDS",
    RANKS = "RANKS",
    SCHEDULING = "SCHEDULING",
    SCHOOL_SESSIONS = "SCHOOL_SESSIONS",
    STUDENT_COMMENTS = "STUDENT_COMMENTS",
    STUDENT_QUESTIONS = "STUDENT_QUESTIONS",
    STUDENT_CERTIFICATES = "STUDENT_CERTIFICATES",
    THEMES = "THEMES",
    PROFILE_SETTINGS = "PROFILE_SETTINGS",
    LOGOUT = "LOGOUT",
    TOPBAR = "TOPBAR",
    FEEDBACK_BUTTON = "FEEDBACK_BUTTON",
}

export enum UserRole {
    Admin = "Admin",
    Campus = "Campus",
    CheckIn = "CheckIn",
    Parent = "Parent",
    Report = "Report",
    Student = "Student",
    Teacher = "Teacher",
    Director = "Director",
}
