/**
 * Response from GET /student/{id}/enrollment/${id}
 */
export interface IUserEnrollment {
    data: ILearningPayload;
    title: string;
    type: IEnrollmentType;
}

export enum IEnrollmentType {
    CHAPTER_COLLECTION = "CHAPTER_COLLECTION",
    IFRAME = "IFRAME",
    IFRAME_CHAPTER = "IFRAME_CHAPTER",
    IFRAME_CODE_COMBAT = "IFRAME_CODE_COMBAT",
    IFRAME_CODE_KINGDOMS = "IFRAME_CODE_KINGDOMS",
    IFRAME_P5JS = "IFRAME_P5JS",
    IFRAME_SCRATCH_V2 = "IFRAME_SCRATCH_V2",
    LINK = "LINK",
    TEXT = "TEXT",
}

export interface ILearningPayload {
    chapterCollection?: ILearningChapterCollection[];
    ssoLoginUrl: string;
    text?: string;
    urls?: ILearningLinkInformation[];
}

export interface ILearningChapterCollectionList {
    collections: ILearningChapterCollectionListItem[];
}

export interface ILearningChapterCollectionListItem {
    id: string;
    progress: number;
    thumbnail?: string;
    title: string;
}

export interface ILearningChapterCollection {
    chapters: ILearningChapter[];
    id: string;
    thumbnail?: string;
    title: string;
}

export interface ILearningChapter {
    id: string;
    lessons: ILearningChapterLesson[];
    title: string;
}

export interface ILearningChapterLesson {
    downloadLink?: ILearningChapterLessonDownloadLink;
    id: string;
    progress: number;
    text?: string;
    title: string;
    video?: ILearningChapterLessonVideo;
}

export interface ILearningChapterLessonDownloadLink {
    name: string;
    url: string;
}

export interface ILearningChapterLessonVideo {
    url: string;
}

export interface ILearningLinkInformation {
    description?: string;
    name: string;
    progress: number;
    thumbnail?: string;
    url: string;
}
