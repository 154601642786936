import * as React from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.scss";

export function NotificationsContainer() {
    const { pathname } = useLocation();

    React.useEffect(() => {
        toast.dismiss();
    }, [pathname]);

    return (
        <ToastContainer
            autoClose={false}
            closeOnClick={false}
            newestOnTop={true}
            pauseOnHover={true}
            position={"top-right"}
            toastClassName={styles.toast}
        />
    );
}
