import { AxiosError, AxiosResponse } from "axios";

export class FetcherError<T> extends Error {
    public static fromAxiosError<T>(error: AxiosError<T>): FetcherError<T> {
        const data = error.response?.data as any;
        return new FetcherError(error, data?.code, data?.message || "");
    }

    public constructor(
        public readonly error: AxiosError<T>,
        public readonly errorCode?: string,
        public readonly errorMessage?: string,
    ) {
        super(
            `HTTP (${error.response?.status} - ${error.response?.statusText}): ${errorMessage}`,
        );
    }

    public get response(): AxiosResponse<T> | undefined {
        return this.error.response;
    }
}
