import { ILoginSuccessResponse, ILoginResponseSchool, IWhoAmIResponse, ITheme } from "@brite-inc/ui-remote/dist";
import { FeatureName } from "./constants";

let cookieStorageKey: string;
let cookieStorageThemeKey: string;

export const setupLoginData = (cookieName: string) => {
    cookieStorageKey = cookieName;
    cookieStorageThemeKey = `${cookieStorageKey}-themes`;
};

export type ILoginPersistentFeatures = {
    [key in FeatureName]: boolean;
};

export interface ILoginDataUser {
    avatar: string;
    familyId?: string;
    id: string;
    name: string;
    roles: string[];
    type: string;
}

export interface ILoginDataSchool {
    features: ILoginPersistentFeatures;
    id: string;
    name: string;
}

export interface ILoginDataThemePersisted {
    selectedThemeName?: string;
}

export interface ILoginDataPersisted {
    selectedSchoolId?: string;
    selectedThemeName?: string;
    userId: string;
    userRoles: string[];
    token?: string;
}

export interface ILoginDataConstructorValues {
    schools: ILoginDataSchool[];
    selectedSchoolIdx: number;
    user: ILoginDataUser;
    themes: ITheme[];
    selectedThemeName: string;
    token?: string;
}

export class LoginData {
    public readonly schools: ILoginDataSchool[];
    public readonly selectedSchoolIdx: number;
    public readonly user: ILoginDataUser;
    public readonly themes: ITheme[];
    public readonly selectedThemeName: string;
    public readonly token?: string;

    public constructor(loginDataValues: ILoginDataConstructorValues) {
        this.schools = loginDataValues.schools;
        this.selectedSchoolIdx = loginDataValues.selectedSchoolIdx;
        this.user = loginDataValues.user;
        this.themes = loginDataValues.themes;
        this.selectedThemeName = loginDataValues.selectedThemeName;
        this.token = loginDataValues.token;

        if (!cookieStorageKey) {
            throw Error("Please setup LoginData first using `setupLoginData` function");
        }
    }

    private static getFeaturesFromFeaturesList(featuresList: string[]): ILoginPersistentFeatures {
        return {
            [FeatureName.BUSINESS_LOG]: false,
            [FeatureName.HOME_PROJECTS]: featuresList.includes("HomeProjects"),
            [FeatureName.NON_CONTIGUOUS_ENROLLMENTS]: featuresList.includes("NonContiguousEnrollments"),
            [FeatureName.PUNCH_CARDS]: featuresList.includes("PunchCards"),
            [FeatureName.RANKS]: featuresList.includes("Ranks"),
            [FeatureName.SCHEDULING]: featuresList.includes("Scheduling"),
            [FeatureName.SCHOOL_SESSIONS]: featuresList.includes("SchoolSessions"),
            [FeatureName.STUDENT_COMMENTS]: featuresList.includes("StudentComments"),
            [FeatureName.STUDENT_QUESTIONS]: featuresList.includes("StudentQuestions"),
            [FeatureName.STUDENT_CERTIFICATES]: featuresList.includes("RankCompletionCertificates"),
            [FeatureName.CURRICULUM]: featuresList.includes("Curriculum"),
            [FeatureName.PROJECT_RESOURCES]: featuresList.includes("ProjectResources"),
            [FeatureName.THEMES]: featuresList.includes("Themes"),
            [FeatureName.PROFILE_SETTINGS]: !featuresList.includes("HideProfileSettings"),
            [FeatureName.LOGOUT]: !featuresList.includes("HideLogout"),
            [FeatureName.TOPBAR]: !featuresList.includes("HideTopBar"),
            [FeatureName.FEEDBACK_BUTTON]: !featuresList.includes("HideFeedbackButton"),
        };
    }

    public static fromAuthResponse({
        input,
        preferences,
        themePreferences,
    }: {
        input: IWhoAmIResponse | ILoginSuccessResponse;
        preferences: ILoginDataPersisted | null;
        themePreferences: ILoginDataThemePersisted | null;
    }): LoginData {
        const schools = (input.schools || []).map((s: ILoginResponseSchool) => ({
            features: this.getFeaturesFromFeaturesList(s.customization?.features || []),
            id: s.id,
            name: s.name,
        }));
        const selectedSchoolIndex = schools.findIndex((s: ILoginResponseSchool) => s.id === preferences?.selectedSchoolId);
        const user = {
            avatar: input.avatar,
            familyId: input.familyId,
            id: input.userId,
            name: input.name,
            roles: input.roles,
            type: input.userType,
        };
        const selectedThemeName = themePreferences?.selectedThemeName ?? preferences?.selectedThemeName ?? "default";

        return new LoginData({
            schools,
            selectedSchoolIdx: selectedSchoolIndex < 0 ? 0 : selectedSchoolIndex,
            user,
            themes: input.themes,
            selectedThemeName: selectedThemeName,
            token: input.token,
        });
    }

    public static fromStorage(storage: Storage | null, storageKey: string = cookieStorageKey): ILoginDataPersisted | null {
        if (!storage) {
            return null;
        }

        const input = storage.getItem(storageKey);
        if (!input) {
            return null;
        }
        return JSON.parse(input);
    }

    public static themesFromStorage(storage: Storage | null): ILoginDataThemePersisted | null {
        return this.fromStorage(storage, cookieStorageThemeKey);
    }

    /**
     * Remove previously stored (if any) preference data from local browser storage.
     */
    public static removeFromStorage(storage: Storage | null) {
        if (!storage) {
            return;
        }

        storage.removeItem(cookieStorageKey);
        storage.removeItem(cookieStorageThemeKey);
    }

    /**
     * Store preference data on local browser storage.
     */
    public store(storage: Storage | null): void {
        if (!storage) {
            return;
        }

        const serialized: ILoginDataPersisted = {
            selectedSchoolId: this.selectedSchool.id,
            userId: this.user.id,
            userRoles: this.user.roles,
            selectedThemeName: this.selectedThemeName,
            token: this.token,
        };

        storage.setItem(cookieStorageKey, JSON.stringify(serialized));
        storage.setItem(
            cookieStorageThemeKey,
            JSON.stringify({
                selectedThemeName: this.selectedThemeName,
            }),
        );
    }

    public get selectedSchool() {
        return this.schools[this.selectedSchoolIdx];
    }
}
