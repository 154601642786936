import * as React from "react";
import { IRemoteCommand } from ".";
import { RemoteCommand } from "./RemoteCommand";

const RemoteCommandContext = React.createContext<IRemoteCommand>(
    new RemoteCommand(""),
);

interface IProps {
    children: React.ReactNode;
    remoteCommand: IRemoteCommand;
}

export const RemoteCommandProvider = (props: IProps) => {
    const { children, remoteCommand } = props;
    const Provider = RemoteCommandContext.Provider;
    return <Provider value={remoteCommand}>{children}</Provider>;
};

export const useRemoteCommand = () => {
    return React.useContext(RemoteCommandContext);
};
