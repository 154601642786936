import { toast } from "react-toastify";

export const useNotification = () => {
    return {
        error: (title: string, error?: Error) => {
            const message = error ? `${title} - ${error.message}` : title;
            toast.error(message);
        },
        success: (text: string, autoClose: number | false = 3000) => {
            toast.success(text, {
                autoClose,
            });
        },
    };
};
