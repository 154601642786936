import * as React from "react";
import { useRemoteCommand } from "@brite-inc/ui-remote/dist";
import { IWhoAmIResponse } from "@brite-inc/ui-remote/src/responseTypes/WhoAmI";
import { useFetcher } from "../fetcher";
import { getLocalStorage } from "../localStorage";
import { LoginData } from "./LoginData";

const LoginDataContext = React.createContext<{
    loginData: LoginData | null;
    setLoginData: (loginData: LoginData) => void;
    removeLoginData: () => void;
}>({
    loginData: null,
    setLoginData: () => ({}),
    removeLoginData: () => ({}),
});

interface LoginDataProviderProps {
    loginData?: LoginData | null;
    children: ({ isReady, loginData }: { isReady: boolean; loginData?: LoginData | null }) => React.ReactNode;
}

export const LoginDataProvider = (props: LoginDataProviderProps) => {
    const [loginData, setLoginData] = React.useState(props.loginData || null);
    const [isReady, setReady] = React.useState(false);
    const remoteCommand = useRemoteCommand();
    const fetchApi = useFetcher();

    React.useEffect(() => {
        if (loginData) {
            // We already have a login data set (used for testing purposes)
            return setReady(true);
        }

        // Ensure existing user session is still active
        fetchApi({
            throwOnUnauthorized: true,
            promise: remoteCommand.whoAmI(),
            onResolve: (response: IWhoAmIResponse) => {
                const storage = getLocalStorage();

                const preferences = LoginData.fromStorage(storage);
                const themePreferences = LoginData.themesFromStorage(storage);

                const createdLoginData = LoginData.fromAuthResponse({
                    input: response,
                    preferences,
                    themePreferences,
                });

                if (response.token) {
                    createdLoginData.store(storage);
                }

                setLoginData(createdLoginData);
                setReady(true);
            },
            onReject: () => {
                LoginData.removeFromStorage(getLocalStorage());
                setReady(true);
            },
        });
    }, []);

    const handleSetLoginData = (updatedLoginData: LoginData) => {
        setLoginData(updatedLoginData);
        updatedLoginData.store(getLocalStorage());
    };

    const handleRemoveLoginData = () => {
        setLoginData(null);
        LoginData.removeFromStorage(getLocalStorage());
    };

    const Provider = LoginDataContext.Provider;

    return (
        <Provider
            value={{
                loginData,
                setLoginData: handleSetLoginData,
                removeLoginData: handleRemoveLoginData,
            }}
        >
            {props.children({
                isReady,
                loginData,
            })}
        </Provider>
    );
};

export const useLoginData = () => {
    return React.useContext(LoginDataContext);
};
