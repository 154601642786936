import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { IconName } from "../../types";

interface IProps {
    className?: string;
    name: IconName;
    size?: SizeProp;
    title?: string;
}

export function Icon(props: IProps) {
    return (
        <FontAwesomeIcon
            icon={props.name}
            className={props.className}
            size={props.size}
            title={props.title}
        />
    );
}
