export function getLocalStorage() {
    let storage;

    try {
        storage = localStorage;
    } catch (e) {
        storage = null;

        let errorMessage = "Unable to access the localStorage";

        if (e instanceof Error) {
            errorMessage = `${errorMessage}: ${e.message}`;
        }

        console.warn(errorMessage);
    }

    return storage;
}
