import { ITheme } from "@brite-inc/ui-remote";
import { ILoginPersistentFeatures, ILoginDataUser, ILoginDataSchool, LoginData } from "./LoginData";

interface IBuildLoginData {
    selectedSchool?: {
        features?: ILoginPersistentFeatures;
        id?: string;
        name?: string;
    };
    user?: ILoginDataUser;
    schools?: ILoginDataSchool[];
    themes?: ITheme[];
}

export function buildLoginData({ selectedSchool, user, schools, themes }: IBuildLoginData): LoginData {
    return {
        schools: schools ?? [
            {
                id: "1",
                name: "Default School",
                features: {
                    BUSINESS_LOG: true,
                    CURRICULUM: true,
                    HOME_PROJECTS: true,
                    NON_CONTIGUOUS_ENROLLMENTS: false,
                    PROJECT_RESOURCES: true,
                    PUNCH_CARDS: true,
                    RANKS: true,
                    SCHEDULING: true,
                    SCHOOL_SESSIONS: true,
                    STUDENT_COMMENTS: true,
                    STUDENT_CERTIFICATES: true,
                    STUDENT_QUESTIONS: true,
                    THEMES: true,
                    LOGOUT: true,
                    PROFILE_SETTINGS: true,
                    TOPBAR: true,
                    FEEDBACK_BUTTON: true
                },
            },
            {
                id: "2",
                name: "Another School",
                features: {
                    BUSINESS_LOG: true,
                    CURRICULUM: true,
                    HOME_PROJECTS: true,
                    NON_CONTIGUOUS_ENROLLMENTS: false,
                    PROJECT_RESOURCES: true,
                    PUNCH_CARDS: true,
                    RANKS: true,
                    SCHEDULING: true,
                    SCHOOL_SESSIONS: true,
                    STUDENT_COMMENTS: true,
                    STUDENT_CERTIFICATES: true,
                    STUDENT_QUESTIONS: true,
                    THEMES: true,
                    LOGOUT: true,
                    PROFILE_SETTINGS: true,
                    TOPBAR: true,
                    FEEDBACK_BUTTON: true,
                },
            },
        ],
        selectedSchool: {
            features: selectedSchool?.features || {
                BUSINESS_LOG: true,
                CURRICULUM: true,
                HOME_PROJECTS: true,
                NON_CONTIGUOUS_ENROLLMENTS: true,
                PROJECT_RESOURCES: true,
                PUNCH_CARDS: true,
                RANKS: true,
                SCHEDULING: true,
                SCHOOL_SESSIONS: true,
                STUDENT_COMMENTS: true,
                STUDENT_QUESTIONS: true,
                STUDENT_CERTIFICATES: true,
                THEMES: true,
                LOGOUT: true,
                PROFILE_SETTINGS: true,
                TOPBAR: true,
                FEEDBACK_BUTTON: true,
            },
            id: selectedSchool?.id || "345",
            name: selectedSchool?.name || "ABC",
        },
        selectedSchoolIdx: 0,
        selectedThemeName: "default",
        themes: themes || [],
        user: user || {
            id: "123",
            name: "Bob Ross",
            roles: ["Parent"],
            type: "Parent",
            avatar: "",
        },
        store: jest.fn(),
    };
}
