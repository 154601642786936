import * as React from "react";
import { IRemoteCommandBackend } from "./RemoteCommandInterface";

interface TokenInitializerProps {
    token?: string;
    remoteCommand: IRemoteCommandBackend;
}

export function RemoteCommandTokenInitializer({
    token,
    remoteCommand,
}: TokenInitializerProps) {
    const [interceptorToken, setInterceptorToken] = React.useState<
        string | null
    >(null);
    const [interceptorId, setInterceptorId] = React.useState<number | null>(
        null,
    );

    React.useEffect(() => {
        // When the user logs out the input token becomes undefined but interceptorToken exists
        const shouldRemoveTokenInterceptor = interceptorToken && !token;

        // We need to remove interceptor and clear interceptorToken
        if (shouldRemoveTokenInterceptor) {
            remoteCommand.removeTokenInterceptor(interceptorId!);
            setInterceptorId(null);
            setInterceptorToken(null);
        }

        // If interceptor token exists we don't need to set it again
        // as well as adding interceptor which already exists
        if (interceptorToken) {
            return;
        }

        // If no token passed means the user is not logged in or not yet called whoami
        // This means we don't need to set interceptorToken and add interceptor yet
        if (!token) {
            return;
        }

        // Set interceptor token and add an interceptor
        setInterceptorToken(token);

        // Keep interceptorId in order to be able to remove it later if needed
        const id = remoteCommand.addTokenInterceptor(token);
        setInterceptorId(id);
    }, [token]);

    return null;
}

RemoteCommandTokenInitializer.displayName = "RemoteCommandTokenInitializer";
