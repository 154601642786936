import * as React from "react";
import { useHistory } from "react-router-dom";
import { useNotification } from "../notifications";
import { useLoginData } from "../loginData";
import { FetcherError } from "./FetcherError";

interface IProps<T> {
    promise: Promise<T>;
    onResolve?: (result: T) => void;
    onReject?: (error: any) => void;
    onFinally?: () => void;
    throwOnUnauthorized?: boolean;
}

let loginPath: string;

export const setupFetcher = (loginPathStr: string) => {
    loginPath = loginPathStr;
};

export const useFetcher = () => {
    const notification = useNotification();
    const ignoreRequestRef = React.useRef(false);
    const history = useHistory();
    const { removeLoginData } = useLoginData();
    React.useEffect(() => {
        return () => {
            ignoreRequestRef.current = true;
        };
    }, []);

    if (!loginPath) {
        throw Error("Please setup fetcher first using `setupFetcher` function");
    }

    function fetchApi<T>(props: IProps<T>) {
        const { promise, onReject, onResolve, onFinally, throwOnUnauthorized = false } = props;
        promise
            .then((result) => {
                if (ignoreRequestRef.current) {
                    return;
                }
                if (onResolve) {
                    onResolve(result);
                }
            })
            .catch((error) => {
                if (ignoreRequestRef.current) {
                    return;
                }
                if (error.response && error.response.status === 401) {
                    if (!throwOnUnauthorized) {
                        removeLoginData();
                        history.push(`${loginPath}?sessionExpired`);
                        return;
                    }
                }

                const refinedError = error.isAxiosError ? FetcherError.fromAxiosError(error) : error;
                if (onReject && error.isAxiosError) {
                    // Caller is notified only for Axios errors
                    // Other error types are treated as unhandled (below)
                    return onReject(refinedError);
                }
                throw refinedError;
            })
            .catch((error) => {
                notification.error("Unhandled error", error);
                if (process.env.NODE_ENV !== "test") {
                    throw error;
                }
            })
            .finally(() => {
                if (ignoreRequestRef.current) {
                    return;
                }
                if (onFinally) {
                    onFinally();
                }
            });
    }
    return fetchApi;
};
