import axios, { AxiosRequestConfig } from "axios";
import { IWhoAmIResponse } from "./responseTypes/WhoAmI";
import { IUser } from "./responseTypes/GetUser";
import { IAvatarListEntry } from "./responseTypes/GetAvatarsList";
import { IFamilyList } from "./responseTypes/GetFamilyList";
import { IUserList, IUserListEntry } from "./responseTypes/GetUserList";
import {
    IGetSchoolSessionList,
    IGetSchoolSessionListEntry,
} from "./responseTypes/GetSchoolSessionList";
import { IStudentEnrollmentList } from "./responseTypes/GetStudentEnrollmentList";
import { IFamily } from "./responseTypes/GetFamily";
import { IUserPasswordReset } from "./responseTypes/UserPasswordReset";
import { IHeartbeat } from "./responseTypes/Heartbeat";
import {
    ILearningChapterCollection,
    ILearningChapterCollectionList,
    IUserEnrollment,
} from "./responseTypes/GetEnrollment";
import { IGetHomeProjectList } from "./responseTypes/GetHomeProjectList";
import { IStudentProgress } from "./responseTypes/GetStudentProgress";
import { ICreateFamilyResponse } from "./responseTypes/CreateFamily";
import { ILoginResponse } from "./responseTypes/Login";
import { ICommentList } from "./responseTypes/GetUserCommentsList";
import { ICreateUserComment } from "./responseTypes/CreateUserComment";
import { IPunchcardsList } from "./responseTypes/GetUserPunchcardsList";
import { IPunchcardPrizeLevels } from "./responseTypes/IPunchcardPrizeLevels";
import { IPunchcardExchange } from "./responseTypes/IPunchcardExchange";
import { IPunchcardRedeem } from "./responseTypes/IPunchcardRedeem";
import { IPunchcardPunch } from "./responseTypes/IPunchcardPunch";
import {
    ICertificateList,
    UserCertificateStatus,
} from "./responseTypes/GetUserCertificateList";
import { IDetailedUserActivityResponse } from "./responseTypes/DetailedUserActivity";
import { IUserActivityResponse } from "./responseTypes/UserActivity";
import { IDetailedSchoolActivityResponse } from "./responseTypes/DetailedSchoolActivity";
import { IAppConfig } from "./responseTypes/GetAppConfig";
import {
    ISchoolCurriculumList,
    ISchoolCurriculum,
} from "./responseTypes/GetSchoolCurriculum";
import { ISchoolProjectsList } from "./responseTypes/GetSchoolProjects";
import {
    ISchoolClassroom,
    ISchoolClassroomList,
} from "./responseTypes/GetSchoolClassrooms";
import { IFamilyInput } from "./inputTypes/CreateFamily";
import { ISchoolClassroomInput } from "./inputTypes/CreateSchoolClassroom";
import { ISchoolCurriculumInput } from "./inputTypes/UpdateSchoolCurriculum";
import { ICustomer } from "./responseTypes/Customer";
import { ICustomerInput } from "./inputTypes/UpdateCustomer";
import { IRemoteCommandBackend } from "./RemoteCommandInterface";
import { ICreateClassroomResponse } from "./responseTypes/CreateClassroom";

export class RemoteCommand implements IRemoteCommandBackend {
    public constructor(
        public readonly apiBase: string,
        public readonly reportApiBase?: string,
    ) {}

    private static apiClient = axios.create({
        headers: {
            "Content-Type": "application/json",
        },
        responseType: "json",
        withCredentials: true,
    });

    public appConfig(): Promise<IAppConfig> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: "config",
        });
    }

    public createUser(schoolId: string, user: IUser): Promise<any> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                ...user,
                schoolId,
            },
            url: user.userType.toLowerCase(),
        });
    }

    public enrollStudent(
        schoolId: string,
        studentId: string,
        completeEnrollment: boolean,
        courseIds: string[],
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            url: `student/${studentId}/enrollment`,
            data: {
                completeEnrollment,
                courseIds,
                schoolId,
            },
        });
    }

    public extendStudentSession(
        schoolId: string,
        userId: string,
        sessionId: string,
        duration: number,
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                extension: duration,
                schoolId,
            },
            url: `student/${userId}/session/${sessionId}`,
        });
    }

    public getFamily(familyId: string): Promise<IFamily> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: `family/${familyId}`,
        });
    }

    public getUser(userType: string, userId: string): Promise<IUser> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: `${userType.toLowerCase()}/${userId}`,
        });
    }

    public getAvatarsList(
        schoolId: string,
        pageSize: number,
        nextToken?: string,
    ): Promise<IAvatarListEntry> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            params: {
                nextToken,
                maxResults: pageSize,
                schoolId,
            },
            url: `avatar`,
        });
    }

    public getEnrollment(
        userId: string,
        enrollmentId: string,
    ): Promise<IUserEnrollment> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: `student/${userId}/enrollment/${enrollmentId}`,
        });
    }

    public getEnrollmentCollectionList(
        userId: string,
        enrollmentId: string,
    ): Promise<ILearningChapterCollectionList> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: `student/${userId}/enrollment/${enrollmentId}/collection`,
        });
    }

    public getEnrollmentCollection(
        userId: string,
        enrollmentId: string,
        collectionId: string,
    ): Promise<ILearningChapterCollection> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: `student/${userId}/enrollment/${enrollmentId}/collection/${collectionId}`,
        });
    }

    public getFamilyList(
        schoolId: string,
        status: "Active" | "Inactive",
    ): Promise<IFamilyList> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            params: {
                schoolId,
                status,
            },
            url: `family`,
        });
    }

    public getHomeProjectList(schoolId: string): Promise<IGetHomeProjectList> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            params: {
                schoolId,
            },
            url: "project",
        });
    }

    public getStaffList(
        schoolId: string,
        status: "Active" | "Inactive",
    ): Promise<IUserListEntry[]> {
        return this.request<IUserList>({
            baseURL: this.apiBase,
            method: "GET",
            params: {
                schoolId,
                status,
            },
            url: `staff`,
        }).then((res) => res.staff || []);
    }

    public getStudentEnrollmentList(
        studentId: string,
    ): Promise<IStudentEnrollmentList> {
        return this.request<IStudentEnrollmentList>({
            baseURL: this.apiBase,
            method: "GET",
            url: `student/${studentId}/enrollment`,
        });
    }

    public getStudentList(
        schoolId: string,
        status: "Active" | "Inactive" | "Locked",
    ): Promise<IUserListEntry[]> {
        return this.request<IUserList>({
            baseURL: this.apiBase,
            method: "GET",
            params: {
                schoolId,
                status,
            },
            url: `student`,
        }).then((res) => res.students || []);
    }

    public getStudentSessionList(
        schoolId: string,
    ): Promise<IGetSchoolSessionListEntry[]> {
        return this.request<IGetSchoolSessionList>({
            baseURL: this.apiBase,
            method: "GET",
            params: {
                schoolId,
                status: "Active",
            },
            url: `student/school/session`,
        }).then((res) => res.sessions || []);
    }

    public getStudentProgress(studentId: string): Promise<IStudentProgress> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: `user/${studentId}/progress`,
        });
    }

    public getUserCertificateList(
        schoolId: string,
        status: UserCertificateStatus,
    ): Promise<ICertificateList> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            params: {
                status,
            },
            url: `/school/${schoolId}/certificate`,
        });
    }

    public getUserCommentsList(
        studentId: string,
        nextToken?: string,
        pageSize?: number,
        visibility?: string,
    ): Promise<ICommentList> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            params: {
                nextToken,
                limit: pageSize ?? 1,
                visibility: visibility === "All" ? null : visibility,
            },
            url: `user/${studentId}/comment`,
        });
    }

    public createUserComment(
        studentId: string,
        comment: {
            comment: string;
            rating?: number;
            visibility: string;
            sessionId?: string;
        },
    ): Promise<ICreateUserComment> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: { ...comment },
            url: `user/${studentId}/comment`,
        });
    }

    public updateUserCertificate(
        schoolId: string,
        certificateId: string,
        status: UserCertificateStatus,
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                status,
            },
            url: `/school/${schoolId}/certificate/${certificateId}`,
        });
    }

    public updateUserComment(
        studentId: string,
        commentId: string,
        comment: {
            comment: string;
            rating?: number;
            visibility: string;
            sessionId?: string;
        },
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                ...comment,
                visibility:
                    comment.visibility === "All"
                        ? "Parents"
                        : comment.visibility,
            },
            url: `user/${studentId}/comment/${commentId}`,
        });
    }

    public deleteUserComment(
        studentId: string,
        commentId: string,
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "DELETE",
            data: {},
            url: `user/${studentId}/comment/${commentId}`,
        });
    }

    public getStudentPunchcards(
        studentId: string,
        params?: {
            status: "Started" | "Exchanging" | "Completed" | "Redeemed";
        },
    ): Promise<IPunchcardsList> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            params,
            url: `user/${studentId}/punchcard`,
        });
    }

    public getPunchcardPrizes(): Promise<IPunchcardPrizeLevels> {
        return new Promise((resolve) => {
            resolve({
                levels: [
                    {
                        id: "1",
                        value: 1,
                        prizes: [
                            {
                                id: "1",
                                title: "Notebook",
                                levelValue: 1,
                            },
                            {
                                id: "2",
                                title: "Mousepad",
                                levelValue: 1,
                            },
                        ],
                    },
                    {
                        id: "2",
                        value: 2,
                        prizes: [
                            {
                                id: "3",
                                title: "Lego Robot",
                                levelValue: 2,
                            },
                        ],
                    },
                ],
            });
        });
    }

    public punchPunchcard(
        studentId: string,
        reason: string,
        sessionId?: string,
    ): Promise<IPunchcardPunch> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                reason,
                sessionId,
            },
            url: `user/${studentId}/punchcard/punch`,
        });
    }

    public exchangePunchcards(
        studentId: string,
        count: number,
    ): Promise<IPunchcardExchange> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                count,
            },
            url: `user/${studentId}/punchcard/exchange`,
        });
    }

    public redeemPunchcards(
        studentId: string,
        count: number,
    ): Promise<IPunchcardRedeem> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                count,
            },
            url: `user/${studentId}/punchcard/redeem`,
        });
    }

    public deletePunch(
        studentId: string,
        punchCardId: string,
        punchId: string,
    ): Promise<boolean> {
        return this.request({
            baseURL: this.apiBase,
            method: "DELETE",
            data: {},
            url: `user/${studentId}/punchcard/${punchCardId}/${punchId}`,
        });
    }

    public heartbeat(): Promise<IHeartbeat> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: "auth/heartbeat",
        });
    }

    public logout(): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            url: "auth/logout",
        });
    }

    public resetUserPassword(user: IUser): Promise<IUserPasswordReset> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: user,
            url: `user/${user.userId}/reset`,
        });
    }

    public updateEnrollmentCollectionProgress(
        schoolId: string,
        userId: string,
        enrollmentId: string,
        collectionId: string,
        chapterId: string,
        lessonId: string,
        progress: number,
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                lesson: {
                    chapterId,
                    collectionId,
                    lessonId,
                },
                progress,
                schoolId,
            },
            url: `student/${userId}/enrollment/${enrollmentId}/progress`,
        });
    }

    public updateEnrollmentUrlProgress(
        schoolId: string,
        userId: string,
        enrollmentId: string,
        url: string,
        progress: number,
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                progress,
                schoolId,
                url,
            },
            url: `student/${userId}/enrollment/${enrollmentId}/progress`,
        });
    }

    public updateFamily(family: IFamily): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: family,
            url: `family/${family.familyId}`,
        });
    }

    public updateUser(user: IUser): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: user,
            url: `${user.userType.toLowerCase()}/${user.userId}`,
        });
    }

    public createFamily(
        schoolId: string,
        families: IFamilyInput[],
    ): Promise<ICreateFamilyResponse> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            url: "family",
            data: {
                families,
                schoolId,
            },
        });
    }

    public detailedSchoolActivity(
        customerId: string,
        year: string | number,
        month: string | number,
        creatorId: string,
    ): Promise<IDetailedSchoolActivityResponse> {
        if (!this.reportApiBase) {
            throw Error("Please pass `reportApiBase` param to constructor");
        }

        return this.request({
            baseURL: this.reportApiBase,
            method: "GET",
            url: `dashboard/customer/${customerId}/school-activity/detail/?year=${year}&month=${month}&creatorId=${creatorId}`,
        });
    }

    public detailedUserActivity(
        customerId: string,
        year: string | number,
        month: string | number,
        creatorId: string,
    ): Promise<IDetailedUserActivityResponse> {
        if (!this.reportApiBase) {
            throw Error("Please pass `reportApiBase` param to constructor");
        }

        return this.request({
            baseURL: this.reportApiBase,
            method: "GET",
            url: `dashboard/customer/${customerId}/user-activity/detail/?year=${year}&month=${month}&creatorId=${creatorId}`,
        });
    }

    public userActivity(
        customerId: string,
        year: string | number,
        creatorId: string,
    ): Promise<IUserActivityResponse> {
        if (!this.reportApiBase) {
            throw Error("Please pass `reportApiBase` param to constructor");
        }

        return this.request({
            baseURL: this.reportApiBase,
            method: "GET",
            url: `dashboard/customer/${customerId}/user-activity/?year=${year}&creatorId=${creatorId}`,
        });
    }

    public getSchoolCurriculumList(
        schoolId: string,
        params?: {
            status?: string;
        },
    ): Promise<ISchoolCurriculumList> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            params,
            url: `school/${schoolId}/curriculum`,
        });
    }

    public getSchoolCurriculum(
        schoolId: string,
        curriculumId: string,
        params?: {
            includeResources: boolean;
        },
    ): Promise<ISchoolCurriculum> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            params,
            url: `school/${schoolId}/curriculum/${curriculumId}`,
        });
    }

    public getSchoolProjects(schoolId: string): Promise<ISchoolProjectsList> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: `school/${schoolId}/project`,
        });
    }

    public updateSchoolCurriculum(
        schoolId: string,
        curriculumId: string,
        curriculum: ISchoolCurriculumInput,
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            url: `school/${schoolId}/curriculum/${curriculumId}`,
            data: {
                ...curriculum,
            },
        });
    }

    public getCustomer(): Promise<ICustomer> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: `/customer`,
        });
    }

    public updateCustomer(customer: ICustomerInput): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            url: `/customer`,
            data: customer,
        });
    }

    public getClassrooms(
        schoolId: string,
        params?: {
            status?: string;
        },
    ): Promise<ISchoolClassroomList> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            params,
            url: `school/${schoolId}/classroom`,
        });
    }

    public getClassroom(
        schoolId: string,
        classroomId: string,
    ): Promise<ISchoolClassroom> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: `school/${schoolId}/classroom/${classroomId}`,
        });
    }

    public createClassroom(
        schoolId: string,
        classroom: ISchoolClassroomInput,
    ): Promise<ICreateClassroomResponse> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                ...classroom,
            },
            url: `school/${schoolId}/classroom`,
        });
    }

    public updateClassroom(
        schoolId: string,
        classroomId: string,
        classroom: ISchoolClassroomInput,
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                ...classroom,
            },
            url: `school/${schoolId}/classroom/${classroomId}`,
        });
    }

    public moveStudentsToClassroom(
        schoolId: string,
        classroomId: string,
        targetClassroomId: string,
        userIds: string[],
    ): Promise<void> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            data: {
                targetClassroomId,
                userIds,
            },
            url: `school/${schoolId}/classroom/${classroomId}/move`,
        });
    }

    public whoAmI(): Promise<IWhoAmIResponse> {
        return this.request({
            baseURL: this.apiBase,
            method: "GET",
            url: "auth/whoami",
        });
    }

    public login(login: string, password: string): Promise<ILoginResponse> {
        return this.request({
            baseURL: this.apiBase,
            method: "POST",
            url: "auth/login",
            data: {
                login,
                password,
            },
            withCredentials: true,
        });
    }

    public addTokenInterceptor(token: string): number {
        const interceptorId = RemoteCommand.apiClient.interceptors.request.use(
            (config) => {
                config.headers["Brite-Token"] = token;

                return config;
            },
        );

        return interceptorId;
    }

    public removeTokenInterceptor(interceptorId: number): void {
        RemoteCommand.apiClient.interceptors.request.eject(interceptorId);
    }

    private request<T>(config: AxiosRequestConfig): Promise<T> {
        return RemoteCommand.apiClient
            .request<T>(config)
            .then((res) => res.data);
    }
}
